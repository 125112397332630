@import "../node_modules/uikit/src/scss/variables-theme.scss";
@import "../node_modules/uikit/src/scss/mixins-theme.scss";
@import "../node_modules/uikit/src/scss/uikit-theme.scss";

/* You can add global styles to this file, and also import other style files */

:root {
  --primary-re: #77b22a;
  --primary-re-hover: #5a9126;
  --primary: #77b22a;
  --secondary-re: #131f28;
  --danger-re: #e74c3c;
  --warning-re: #f39c12;
}

@media only screen and (min-width : 1200px) and (max-width : 1370px) {
  html {
    font-size: 12px !important;
  }
  .uk-button {
    padding: 0 10px;
  }
}

body, h1, h2, h3, h4, h5, a, p, .uk-button {
  font-family: 'Poppins', sans-serif;
}

.uk-button-primary, .uk-button-primary:active, .uk-button-primary:focus {
  background-color: var(--primary-re);
}

.uk-background-warning{

}

.uk-button-primary:hover {
  background-color: var(--primary-re-hover);
}

.uk-button-primary:disabled {
  color: var(--secondary-re);
  background-color: white;
}

.uk-accordion-title {
  font-size:  1em!important;
  color: #666;
}

.img-fluid {
  width: 80%;
}

.uk-link, .uk-link:hover, .uk-link:active, .uk-link:focus {
  color: var(--primary-re) !important;
  text-decoration: none;
}

input:focus, input:active, input::selection, select:focus, select:active, select::selection  {
  border-color: var(--primary-re) !important;
}

.m-center {
  margin: 0 auto !important;
}

.navbar {
  text-align: center;
  min-height: 4em;
  background-color: white;
  color:  var(--secondary-re);
  margin: 0;
  /*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);*/
}

.sidebar {
  box-shadow: none!important;
}

.inner-shadow {
  box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,.08);
  min-height: calc(100vh - 4em);
  padding-bottom: 30px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 0;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-re-hover);
}

.background_green_re {
  background-color: var(--primary-re);
}

tfoot {
  display: table-header-group!important;
}

.nav-text {
  line-height: 2.5em;
  font-size: 1.5em;
}

.dataTables_empty {
  display: none;
}

.dataTables_length > label > select, .dataTables_filter > label > input {
  height: 40px;
  vertical-align: middle;
  display: inline-block;
  padding: 0 10px;
  background: #fff;
  color: #666;
  border: 1px solid #e5e5e5;
}

.paginate_button{
  background-color: var(--primary-re) !important;
  border: none!important;
  color: white !important;
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
}

.paginate_button:hover {
  background-color: var(--primary-re-hover) !important;
  text-decoration: none;
}

.paginate_button.current {
  background-color: var(--secondary-re) !important;
}


.center-top-toast {
  top: 100px;
  left:50%;
  margin:0 0 0 -400px;
}

.big-toast {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 50px 15px 15px 50px;
  text-align: center;
  width: 1000px;
  height: 750px;
  border-radius: 3px 3px 3px 3px;
  font-size: 60px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  background-image: none !important;
  line-height: 0.9;
  color: #FFFFFF;
}

.short-success-toast {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 18px 15px 15px 15px;
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 3px 3px 3px 3px;
  font-size: 60px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  background-image: none !important;
  line-height: 0.9;
  color: #FFFFFF;
}

.dark-green-success-toast {
  background-color: #009E60 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

.big-toast-title {
  text-align: center;
  font-size: 100px!important;
}

.big-toast-text {
  font-size: 560px!important;
}


.toast-classification-warning {
  background-color: #ff0000 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

.toast-classification-error {
  background-color: #ff0000 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

.toast-parcel-deleted-error {
  background-color: #ff0000 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

.toast-parcel-data-not-downloaded-yet-error {
  background-color:  #ff0000 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

.toast-classification-5 {
  background-color: #ffff1a !important;
  color: #000000 !important;
}

.toast-classification-4 {
  background-color: #FF1493 !important;
}

.toast-classification-3 {
  background-color: #00FF00 !important;
}

.toast-classification-2 {
  background-color: #0000FF !important;
}

.toast-classification-1 {
  background-color: #FF8000 !important;
}

.dataTables_filter, .dataTables_paginate {
  float:right;
}

.dataTables_length, .dataTables_info{
  float: left;
}

.dataTables_info {
  padding: 10px;
}

.uk-table-hover > tbody > tr:hover, input[type='checkbox']:checked {
  background-color: var(--primary-re) !important;
  color: white;
}

.padding-horizontal-small{
  padding-right: 10px;
  padding-left: 10px;
}

.dataTables_processing {
  top: 64px !important;
  z-index: 11000 !important;
  margin: 0 auto;
  text-align:center;
  background: #fff6ee;
  color: #faa05a;
  width: 300px;
  padding:5px;
}

.uk-list-divider{
  background-color: white;
  border: 1px solid #e5e5e5;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)!important;
}


@media only screen and (max-width : 1400px) {
  h4.uk-text {
    font-size: .9em!important;
  }
}

@media only screen and (min-width : 960px) {
  .sidebar {
    background-color: white;
    min-height: 100vh;
    height: 100%;
    bottom:0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .navbar {
    padding: 0;
  }

  .logo {
    width: 50%;
  }

  .computer-only {
    display:block;
  }
  .mobile-only {
    display: none;
  }


}
@media only screen and (max-width : 959px) {
  .logo {
    height: 2em;
  }
  .sidebar {
    background-color: white;
  }

  .uk-first-column{
    margin-top: 0 !important
  }

  .uk-margin-medium-right.uk-card-body {
    margin-right: 0!important;
  }

  .uk-width-1-1.uk-margin-medium-top {
    margin-top: 0!important;
  }

  .uk-width-1-5 {
    width: 20%!important;
  }

  .uk-width-1-8 {
    width: 12%!important;
  }

  .uk-width-2-5 {
    width: 40%!important;
  }

  .button-opt {
    margin-right: 0!important;
  }
  .nav-text > span {
    display:block;
    line-height: 1.3em;
  }
  .nav-text > button {
    float: none;
  }
  .nav-text {
    font-size: 1.2em;
  }

  .computer-only {
    display:none;
  }
  .mobile-only {
    display: block;
  }

}

.mobile-overflow {
  overflow-x: auto;
  overflow-y: hidden;
}

.button-opt{
  float:right;
  margin-top: .8em;
  margin-right: 40px;
}

.uk-button:disabled{
  cursor: not-allowed!important;
}

kbd {
  font-size: inherit!important;
  color: inherit!important;
  background-color: inherit!important;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}


button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  color: #FFFFFF;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: var(--primary-re);
  pointer-events: auto;
}
.toast-success {
  background-color: var(--primary-re);
}
.toast-error {
  background-color: var(--danger-re);
}
.toast-info {
  background-color: var(--primary-re);
}
.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

.required:after {
  content: '*';
  vertical-align: super;
  font-size: smaller;
  color: var(--danger-re);
}

.sidebar-logo-size {
  width: 90%;
  margin-left: 5%;
  padding-top: 30px;
}

.mb-1{
  margin-bottom: 1px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-23 {
  margin-top: 23px;
}

.mt-100 {
  margin-top: 100px;
}

.pt-10{
  padding-top: 10px;
}

.pt-25 {
  padding-top: 25px;
}

.padding-horizontal-big{
  padding-right: 70px;
  padding-left: 70px;
}

.text-warning {
  color: #ffa500 !important;
  font-weight: 600;
}
